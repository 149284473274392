import * as trace from 'owa-trace';
import { getUserPuid } from 'mini-common-utils/lib/utils/getUserPuid';

function isSessionStorageSupported() {
    let isSupported = false;
    try {
        isSupported = typeof window?.sessionStorage !== 'undefined';
    } catch (error) {
        // This can happen if third party cookies are blocked.
        trace.trace?.warn(`[isSessionStorageSupported]: ${error}`);
    }
    return isSupported;
}

/**
 * Function to get the value stored in session storage for the key passed
 * @param key Session storage key to get the value of
 */
export function getItemFromSessionStorage(key: string) {
    let result = null;
    try {
        if (isSessionStorageSupported()) {
            const sessionStorageKey = getSessionStorageKey(key);
            result = JSON.parse(window.sessionStorage.getItem(sessionStorageKey));
        }
    } catch (error) {
        trace.errorThatWillCauseAlert(`[getItemFromSessionStorage]: ${error}`);
    }

    return result;
}

/**
 * Function to set items in session storage with the key value pair
 * @param key Sesison storage key to set the value in
 * @param value Value to be set
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any --
 * See aka.ms/mini-lint-errors
 *	> Unexpected any. Specify a different type. */
export function setItemInSessionStorage(key: string, value: any) {
    try {
        if (isSessionStorageSupported()) {
            const sessionStorageKey = getSessionStorageKey(key);
            window.sessionStorage.setItem(sessionStorageKey, JSON.stringify(value));
        }
    } catch (error) {
        if (window.sessionStorage.length === 0) {
            // This can be incognito mode as length is zero and error was caught while setting item.
            // In some browsers, QUOTA is 0 in incognito mode
        } else {
            // Log error as genuine quota exceeded.
            // This will help identify if the assumption that users will not have data more than 5 MB is correct or not.
            trace.errorThatWillCauseAlert(`[setItemInSessionStorage]: ${error}`);
        }
    }
}

/**
 * Clears any key/value pairs present in sessionStorage
 */
export const clearSessionStorage = () => {
    try {
        if (isSessionStorageSupported()) {
            window.sessionStorage.clear();
        }
    } catch (error) {
        trace.errorThatWillCauseAlert(`[clearSessionStorage]: ${error}`);
    }
};

/**  Returns session storage object (exposed for unit testing) */
export const getSessionStorage = () => {
    if (isSessionStorageSupported()) {
        return window.sessionStorage;
    }
    return null;
};

export const isInSessionStorage = (key: string): boolean => {
    let isInSessionStorage = false;
    try {
        if (isSessionStorageSupported()) {
            const sessionStorageKey = getSessionStorageKey(key);
            isInSessionStorage = window.sessionStorage.hasOwnProperty(sessionStorageKey);
        }
    } catch (error) {
        // no op;
    }

    return isInSessionStorage;
};

function getSessionStorageKey(key: string) {
    const puid = getUserPuid();
    return `mini-${puid}-${key}`;
}
