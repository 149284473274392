import type Module from 'mini-common-schema/lib/schema/Module';
import {
    lazyGetCalendarBootstrapOptions,
    lazyCalendarBootstrap,
} from 'mini-calendar-bootstrap-config';
import { lazyGetMailBootstrapOptions, lazyMailBootstrap } from 'mini-mail-bootstrap-config';
import { lazyGetPeopleBootstrapOptions, lazyPeopleBootstrap } from 'mini-people-bootstrap-config';
import {
    lazyGetRewardsBootstrapOptions,
    lazyRewardsBootstrap,
} from 'mini-rewards-bootstrap-config';
import type { ModuleConfigMap } from 'mini-start';
import type { BootstrapOptions } from 'mini-bootstrap';

export const moduleToConfigMap: ModuleConfigMap<BootstrapOptions> = {
    [0]: {
        options: lazyGetMailBootstrapOptions,
        bootstrap: lazyMailBootstrap,
    },
    [1]: {
        options: lazyGetCalendarBootstrapOptions,
        bootstrap: lazyCalendarBootstrap,
    },
    [2]: {
        options: lazyGetPeopleBootstrapOptions,
        bootstrap: lazyPeopleBootstrap,
    },
    [3]: {
        options: lazyGetRewardsBootstrapOptions,
        bootstrap: lazyRewardsBootstrap,
    },
};
