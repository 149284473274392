import { type AppName } from 'mini-app-constants';
import type { SupportedModules } from 'mini-common-schema';
import { type Module } from 'mini-common-schema';

const MODULE_APP_MAPPING: Record<SupportedModules, AppName> = {
    [0]: 'Mail',
    [1]: 'Calendar',
    [2]: 'People',
    [3]: 'Rewards',
};

export const getAppNameForModule = (module: Module): string => MODULE_APP_MAPPING[module] ?? 'Mail';
