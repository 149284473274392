export type MiniEntryType =
    | 'paint'
    | 'longtask'
    | 'layout-shift'
    | 'element'
    | 'resource'
    | 'largest-contentful-paint';

export function createPerformanceObserver(
    type: MiniEntryType,
    registration: (list: PerformanceObserverEntryList) => void
): PerformanceObserver | null {
    let performanceObserver: PerformanceObserver | null = null;

    if (
        'PerformanceObserver' in self &&
        'supportedEntryTypes' in self.PerformanceObserver &&
        self.PerformanceObserver.supportedEntryTypes.some(s => s === type)
    ) {
        performanceObserver = new PerformanceObserver(registration);
    }

    return performanceObserver;
}
