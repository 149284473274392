import getMailPath from 'owa-url/lib/getMailPath';
import { type Module } from 'mini-common-schema';

/**
 * Based on the URL pathname determine the Module to load
 */
export const getModuleFromPath = (): Module | null => {
    const pathname = window?.location?.pathname;

    if (pathname) {
        if (pathname.indexOf(`${getMailPath()}rewards`) === 0) {
            return 3;
        } else if (pathname.indexOf('/mail') === 0) {
            return 0;
        } else if (pathname.indexOf('/calendar') === 0) {
            return 1;
        } else if (pathname.indexOf('/people') === 0) {
            return 2;
        }
    }

    return null;
};
