import { calBootstrapDiagnosticsObj } from 'mini-common-bootstrap/lib/calBootstrapDiagnosticsObj';
import { getApp } from 'owa-config/lib/bootstrapOptions';
import { setItemInSessionStorage } from 'mini-session-storage/lib/utils/sessionStorageUtils';

const calDiagnosticsLogs = [];

export const traceBootstrapDiagnostics = (name: string): void => {
    if (getApp() === 'MiniCalendar' || getApp() === 'Calendar') {
        const diagnosticsLabel = '_traceCalDiagnostics';
        calDiagnosticsLogs.push(calBootstrapDiagnosticsObj[name]);
        setItemInSessionStorage(diagnosticsLabel, calDiagnosticsLogs);
    }
};
