import { addBottleneck, markFunction } from 'owa-performance';
import { addSessionDataHeaders } from 'owa-shared-startupdata/lib/addSessionDataHeaders';
import { fetchData } from './fetchData';
import { getBrowserHeight } from 'owa-config';
import { getItem, removeItem, setItem } from 'owa-local-storage';
import { logStartUsage } from 'owa-analytics-start';
import { updateDiagnosticsOnSessionData } from 'owa-shared-start/lib/sessionDiagnostics';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import type { HeadersWithoutIterator } from 'owa-service/lib/RequestOptions';

const FindMessageParametersKey = 'sdmp';

const FindFolderParametersKey = 'sdfp';

type Bottleneck = 'sdmp' | 'sdfp';
type LocalStorageFunnel = any;
type StartupDiagnostics = any;

let findFolderTunnel: LocalStorageFunnel = 0;
let findMessageTunnel: LocalStorageFunnel = 0;

export default markFunction(getSessionData, 'sd');
function getSessionData(): Promise<SessionData> {
    const headers = new Headers();
    const folderParams = getItem(window, FindFolderParametersKey);
    if (folderParams) {
        headers.set('folderParams', folderParams);
        findFolderTunnel = 1;
    }
    const messageParams = getItem(window, FindMessageParametersKey);
    if (messageParams) {
        headers.set('messageParams', messageParams);
        findMessageTunnel = 1;
    }
    const pathname = window.location.pathname;
    addSessionDataHeaders(headers, pathname === '/' ? '' : pathname, {
        initialFolders: 'initialfolders',
        windowHeight: getBrowserHeight(),
    });
    return fetchData(headers, postProcess, processHeaders);
}

function postProcess(settings: SessionData) {
    // Confirm that the session data returned by the StartupData call contains
    // non-empty User and Logon email addresses.
    if (!settings.owaUserConfig?.SessionSettings?.UserEmailAddress) {
        const e = new Error();
        logStartUsage('AcctInit-NoUserEmailAddress', {
            stack: e.stack,
            st: typeof settings,
            uc: typeof settings.owaUserConfig,
            ss: typeof settings.owaUserConfig?.SessionSettings,
            ue: typeof settings.owaUserConfig?.SessionSettings?.UserEmailAddress,
        });
    }

    if (!settings.owaUserConfig?.SessionSettings?.LogonEmailAddress) {
        const e = new Error();
        logStartUsage('AcctInit-NoLogonEmailAddress', {
            stack: e.stack,
            st: typeof settings,
            uc: typeof settings.owaUserConfig,
            ss: typeof settings.owaUserConfig?.SessionSettings,
            ue: typeof settings.owaUserConfig?.SessionSettings?.LogonEmailAddress,
        });
    }

    settings.folderParams &&
        setItem(window, FindFolderParametersKey, JSON.stringify(settings.folderParams));
    settings.messageParams &&
        setItem(window, FindMessageParametersKey, JSON.stringify(settings.messageParams));
    updateDiagnosticsOnSessionData(settings);

    return settings;
}

function processHeaders(headers: HeadersWithoutIterator) {
    let startupDiagnostics: StartupDiagnostics | undefined;
    if (headers) {
        const preloadHeader = headers.get('x-owa-startup-preload');
        if (preloadHeader) {
            addBottleneck('sd_pr', preloadHeader);
        }
        const diagnostics = headers.get('x-owa-startup-diag');
        if (diagnostics) {
            startupDiagnostics = parseInt(diagnostics);
        }
    }
    addLocalStorageBottleneck(FindFolderParametersKey, findFolderTunnel, startupDiagnostics, 4, 1);
    addLocalStorageBottleneck(
        FindMessageParametersKey,
        findMessageTunnel,
        startupDiagnostics,
        8,
        2
    );
}

function addLocalStorageBottleneck(
    bottleneck: Bottleneck,
    funnel: LocalStorageFunnel,
    diagnostics: StartupDiagnostics | undefined,
    useParams: StartupDiagnostics,
    foundParams: StartupDiagnostics
) {
    let computedFunnel = funnel;
    if (diagnostics) {
        if ((diagnostics & useParams) !== 0) {
            computedFunnel = 3;
        } else if ((diagnostics & foundParams) !== 0) {
            computedFunnel = 2;
        }

        // if there was a json exception in startupdata then local storage is not valid so
        // let's delete it so we don't use it next time
        if ((diagnostics & 16) !== 0) {
            removeItem(window, bottleneck);
        }
    }
    addBottleneck(bottleneck, computedFunnel);
}
