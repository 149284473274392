import { ARIA_TENANT_TOKENS } from 'mini-app-analytics/lib/utils/constants';
import { getAppNameForModule } from 'mini-app-module/lib/utils/getAppNameForModule';
import { getModuleFromPath } from 'mini-app-module/lib/utils/getModuleFromPath';
import { getServerAppNameForModule } from 'mini-app-module/lib/utils/getServerAppNameForModule';
import { initiatePerformanceObserverForCriticalResource } from 'mini-common-bootstrap/lib/initiatePerformanceObserverForCriticalResource';
import { miniStart } from 'mini-start';
import { type Module } from 'mini-common-schema';
import { moduleToConfigMap } from 'mini-bootstrap-config';
import { runAfterRequests } from 'mini-bootstrap-config/lib/runAfterRequests';
import 'mini-global-styles';
import type { HandleBootErrorFunction } from 'owa-shared-start/lib/interfaces/StartConfig';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import type StartConfig from 'mini-start/lib/types/StartConfig';

interface AppStartParams {
    handleBootError?: HandleBootErrorFunction;
    overrideBootPromises?: () => Promise<SessionData>;
    onAuthFailure?: (claimsChallenge: string | null) => void;
    onFatalBootError?: () => void;
    onLoaderRemoved?: () => void;
    runBeforeStart?: (config: StartConfig) => Promise<void>;
}

export function mailStart(params: AppStartParams = {}) {
    const {
        runBeforeStart,
        onFatalBootError,
        onLoaderRemoved,
        overrideBootPromises,
        handleBootError,
    } = params;
    const module = getModuleFromPath() ?? 0;
    const startLazyConfig = moduleToConfigMap[module];
    const serverAppName = getServerAppNameForModule(module);
    const appName = getAppNameForModule(module);

    // Register perf observer to start tracking resource load times
    initiatePerformanceObserverForCriticalResource();

    const appStartConfig: StartConfig = {
        app: appName,
        serverAppName,
        runBeforeStart,
        startupAriaToken: ARIA_TENANT_TOKENS[0],
        loadBpos: true,
        onFatalBootError: onFatalBootError,
        bootstrap: startLazyConfig.bootstrap,
        overrideBootPromises,
        runAfterRequests: () => {
            runAfterRequests(module);
        },
        handleBootError,
        onLoaderRemoved,
    };

    miniStart(appStartConfig);
}
