export const calBootstrapDiagnosticsObj = {
    runAfterRequestsStarts: 'RARS',
    bootLocalizationPromiseStarts: 'BLPS',
    runAfterRequestsEnds: 'RARE',
    SessionDataPromiseStarts: 'SDPS',
    SessionDataPromiseEnds: 'SDPE',
    preRenderPromisesStarts: 'PRPS',
    addCalendarPromisesStarts: 'ACPS',
    addCalendarPromisesEnds: 'ACPE',
    preRenderPromisesEnds: 'PRPE',
    initialBootstrapPromiseStarts: 'IBPS',
    bootLocalizationPromiseEnds: 'BLPE',
    runAfterBootLocalizationStarts: 'RABLS',
    runAfterBootLocalizationEnds: 'RABLE',
    initialBootstrapPromiseEnds: 'IBPE',
};
