import { ANDROID_OUTLOOK, ANDROID_TEAMS, IOS_OUTLOOK, IOS_TEAMS } from 'owa-config';
import { getFlagDefaults } from './getFlagDefaults';
import { getOpxHostAppName } from 'mini-opx-config/lib/getOpxHostAppName';
import { isNative } from './utils/isNative';
import type { MiniHostAppFeature } from './schema/MiniHostAppFeature';

/**
 * This function is used to check if a feature is enabled for the current host app.
 * @param flagName - The feature flag name
 * @returns true if the feature is enabled for the current host app, false otherwise
 */
export function isMiniHostAppFeatureEnabled(flagName: MiniHostAppFeature): boolean {
    const featureFlags = getFlagDefaults(flagName);

    if (featureFlags) {
        // First check to see if a hostname specific value is defined
        const hostApp = getOpxHostAppName();
        if (hostApp && hostApp in featureFlags) {
            return featureFlags[hostApp];
        }

        if (isNative() && 'native' in featureFlags) {
            // "native" hostname grouping is deprecated.
            // "miniNative" query param was used by OutlookLite during the early stages of bootstrap
            // The check here is for backward compatibility
            return featureFlags.native as boolean;
        }

        // Check if the feature is enabled for all OPX hosts
        if (
            hostApp &&
            (hostApp === IOS_OUTLOOK ||
                hostApp === ANDROID_OUTLOOK ||
                hostApp === ANDROID_TEAMS ||
                hostApp === IOS_TEAMS) &&
            'opx' in featureFlags
        ) {
            return featureFlags.opx as boolean;
        }

        // If no hostname specific value is defined, return the default value
        // Usually, this is the case when we are in a browser (non-hosted scenario) or
        // Host has the default behavior
        return featureFlags.default;
    }

    return false;
}
