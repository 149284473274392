import { observeResourcePerformance } from 'mini-performance/lib/utils/resourcePerformanceObserver';
import type { ResourceToTrack } from 'mini-performance/lib/utils/resourcePerformanceObserver';
import {
    CRITICAL_NETWORK_CALLS,
    type BootResourceName,
    getPatternForBootResource,
    BOOT_RESOURCE_PATTERN,
} from './resources';

export const CRITICAL_RESOURCES_TO_TRACK: Array<ResourceToTrack> = [
    /**
     * For both session-data and startup-data, the key is sd for aria dashboard plots,
     * and for a session, it will be either session-data or startup depending on the flighting.
     */
    ...CRITICAL_NETWORK_CALLS,

    // critical JS assets
    {
        name: 'sbjs',
        matchPattern: getPatternForBootResource(BOOT_RESOURCE_PATTERN.SharedBoot),
    },
    {
        name: 'mbjs',
        matchPattern: getPatternForBootResource(`${BOOT_RESOURCE_PATTERN.MailBoot}New`),
    },
    {
        name: 'cbjs',
        matchPattern: getPatternForBootResource(`${BOOT_RESOURCE_PATTERN.CalendarBoot}New`),
    },
    {
        name: 'pbjs',
        matchPattern: getPatternForBootResource(`${BOOT_RESOURCE_PATTERN.PeopleBoot}New`),
    },
    {
        name: 'fvjs',
        matchPattern: getPatternForBootResource(BOOT_RESOURCE_PATTERN.FrameVendors),
    },
    {
        name: 'mvjs',
        matchPattern: getPatternForBootResource(BOOT_RESOURCE_PATTERN.MiniVendors),
    },
    {
        name: 'mcjs',
        matchPattern: getPatternForBootResource(BOOT_RESOURCE_PATTERN.MiniCommon),
    },
    {
        name: 'mbjs',
        matchPattern: getPatternForBootResource(BOOT_RESOURCE_PATTERN.MailBoot),
    },
    {
        name: 'cbjs',
        matchPattern: getPatternForBootResource(BOOT_RESOURCE_PATTERN.CalendarBoot),
    },
    {
        name: 'pbjs',
        matchPattern: getPatternForBootResource(BOOT_RESOURCE_PATTERN.PeopleBoot),
    },
];

/**
 * Starts collecting the resource timing for boot critical resources.
 */
export const initiatePerformanceObserverForCriticalResource = () => {
    CRITICAL_RESOURCES_TO_TRACK.forEach(resource => {
        observeResourcePerformance(resource);
    });
};
