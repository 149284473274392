import type { MiniHostAppFlagControl } from './schema/MiniHostAppFlagControl';
import type { MiniHostAppFeature } from './schema/MiniHostAppFeature';

/**
 * This config allows to enable/disable a feature for specific host apps.
 * Each feature is mapped to a {@link MiniHostAppFlagControl} object, which includes:
 *  -> "default" value which controls a feature in non-hosted scenario (mini-owa) and default for all hosted scenarios
 *  -> Hostname specific value to flight a feature for specific host apps.
 */
export const MINI_HOST_APP_FLAGS: Record<MiniHostAppFeature, MiniHostAppFlagControl> = {
    native: {
        default: false,
        // "native" hostname grouping is deprecated and hostname should be used instead
        // This is just for parity with shared packages
        // -> Error Type '{ default: false; native: true; }' is not assignable to type 'MiniHostAppFlagControl'.
        // Object literal may only specify known properties, and 'native' does not exist in type 'MiniHostAppFlagControl'.
        // @ts-expect-error
        native: true,
    },
    ads: {
        default: true,
        opx: false,
    },
    adsHonorGlobalPrivacyControl: {
        default: true,
        outlooklite: false,
        opx: false,
    },
    campaigns: {
        default: true,
        opx: false,
    },
    nativeFeedback: {
        default: false,
        outlooklite: true,
    },
    substrateSearch: {
        default: true,
    },
    loadCultureFromHost: {
        default: false,
        outlooklite: true,
        iosoutlook: true,
        androidoutlook: true,
    },
    adsInFocussedInbox: {
        default: true,
        outlooklite: false,
    },
    syncOwaUserConfigCache: {
        default: false,
        outlooklite: true,
    },
    readOnlyReadingPane: {
        default: false,
        androidteams: true,
        iosteams: true,
    },
    validateBootCache: {
        default: false,
        outlooklite: true,
    },
    offlineSync: {
        default: false,
        outlooklite: true,
    },
    multiAccount: {
        default: false,
        outlooklite: true,
    },
    swipeRefresh: {
        default: false,
        outlooklite: true,
    },
    overrideShowAdPreferencesChecks: {
        default: false,
        androidoutlook: true,
        iosoutlook: true,
    },
    subscribeForHostUpdates: {
        default: false,
        outlooklite: true,
    },
    inboxShopping: {
        default: true,
        outlooklite: false,
    },
    allowTenantBlocking: {
        default: false,
        outlooklite: true,
    },
    miniMeControl: {
        default: true,
        outlooklite: false,
    },
    referFriendsAndFamily: {
        default: false,
        outlooklite: true,
    },
    nativePushNotification: {
        default: false,
        outlooklite: true,
    },
    opxComponentLifecycle: {
        default: false,
        opx: true,
    },
    componentTokenProvider: {
        default: false,
        opx: true,
        outlooklite: true,
    },
    opxDeeplink: {
        default: false,
        opx: true,
    },
    loadThemeFromHostApp: {
        default: false,
        opx: true,
        outlooklite: true,
    },
    authRedirectOnSessionTimeout: {
        default: true,
        outlooklite: false,
        opx: false,
    },
    nativeHost: {
        default: false,
        outlooklite: true,
        androidoutlook: true,
        iosoutlook: true,
        androidteams: true,
        iosteams: true,
    },
    adPrivacyOptionLoadOverride: {
        default: false,
        opx: true,
        outlooklite: true,
    },
    sendInAppFeedbackSubmittedSignal: {
        default: false,
        outlooklite: true,
    },
    nativeUserProvider: {
        default: false,
        outlooklite: true,
    },
    nativeServiceWorker: {
        default: false,
        outlooklite: true,
    },
    nativePerfLog: {
        default: false,
        outlooklite: true,
    },
    hostCampaigns: {
        default: false,
        outlooklite: true,
    },
    multipleAds: {
        default: true,
        outlooklite: false,
    },
    nativeDiagnostics: {
        default: false,
        outlooklite: true,
    },
    vernacularCompose: {
        default: false,
        outlooklite: true,
    },
    miniEventReminders: {
        default: true,
        outlooklite: false,
    },
    nativeModuleSwitcher: {
        default: false,
        outlooklite: true,
    },
    showUSQHeader: {
        default: true,
        androidoutlook: false,
        iosoutlook: false,
    },
    shareViaOutlookLite: {
        default: false,
        outlooklite: true,
    },
    showNPS: {
        default: true,
        outlooklite: false,
        opx: false,
    },
    adShimmer: {
        default: false,
        outlooklite: true,
    },
    fontScale: {
        default: false,
        outlooklite: true,
    },
    fontDensity: {
        default: true,
        outlooklite: false,
        opx: false,
    },
    userTypeLogging: {
        default: false,
        outlooklite: true,
    },
    pinchToZoom: {
        default: false,
        outlooklite: true,
    },
    loadTelemetryEndpointFromHost: {
        default: false,
        outlooklite: true,
    },
    zoom: {
        default: false,
        outlooklite: true,
    },
    otherInboxDynamicAds: {
        default: true,
        outlooklite: false,
    },
    usqPurchaseDisabled: {
        default: false,
        outlooklite: true,
    },
    suggestedUnsubscribe: {
        default: true,
        outlooklite: false,
        opx: false,
    },
    msalTokenFetch: {
        default: true,
        outlooklite: false,
        opx: false,
    },
    adsInLitePrimary: {
        default: false,
        outlooklite: true,
    },
    rewards: {
        default: true,
        outlooklite: false,
        opx: false,
    },
    themes: {
        default: true,
        outlooklite: false,
        opx: false,
    },
    loadCultureFromLite: {
        default: false,
        outlooklite: true,
    },
    miniCopilotProdigi: {
        default: true,
        outlooklite: false,
        opx: false,
    },
    miniSubscriptionSettings: {
        default: true,
        outlooklite: false,
        opx: false,
    },
    // Temporarily disabling USQ for outlooklite and opx scenarios because of the auth issue.
    usqColorCodingEnabled: {
        default: true,
        outlooklite: false,
        opx: false,
    },
    // All copilot scenarios like elaborate, summarize, etc.
    copilot: {
        default: true,
        outlooklite: false,
        opx: false,
    },
};
