import { lazyRunAfterRequestCalendar, lazyRunAfterRequestPeople } from 'mini-run-after-requests';
import { type Module } from 'mini-common-schema';
import { traceBootstrapDiagnostics } from 'mini-calendar-bootstrap-config/lib/utils/traceBootstrapDiagnostics';

export const runAfterRequests = async (module: Module): Promise<void> => {
    // run module specific handlers
    switch (module) {
        case 1:
            traceBootstrapDiagnostics('runAfterRequestsStarts');
            await lazyRunAfterRequestCalendar.importAndExecute();
            traceBootstrapDiagnostics('runAfterRequestsEnds');
            break;
        case 2:
            lazyRunAfterRequestPeople.importAndExecute();
            break;
        default:
            break;
    }
};
