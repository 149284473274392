import { LazyAction, LazyModule } from 'owa-bundling-light';

const lazyModuleForCalendar = new LazyModule(
    () => import(/* webpackChunkName: "RunAfterRequestCalendar" */ './runAfterRequestCalendar')
);

const lazyModuleForPeople = new LazyModule(
    () => import(/* webpackChunkName: "RunAfterRequestPeople" */ './runAfterRequestPeople')
);

export const lazyRunAfterRequestCalendar = new LazyAction(
    lazyModuleForCalendar,
    m => m.runAfterRequestCalendar
);

export const lazyRunAfterRequestPeople = new LazyAction(
    lazyModuleForPeople,
    m => m.runAfterRequestPeople
);
