export type BootResourceName = any;

export const BOOT_RESOURCE_PATTERN = {
    SharedBoot: 'SharedBoot',
    MailBoot: 'MailBoot',
    CalendarBoot: 'CalendarBoot',
    PeopleBoot: 'PeopleBoot',
    FrameVendors: 'framework-vendors',
    MiniVendors: 'mini-vendors',
    MiniCommon: 'mini-common-resource',
};

export const getPatternForBootResource = (baseName: string) => `owa.${baseName}.js`;

export const CRITICAL_NETWORK_CALLS = [
    { name: 'sd', matchPattern: 'sessiondata' },
    { name: 'sd', matchPattern: 'startupdata' },
    { name: 'ubs', matchPattern: 'userbootsettings' },
    { name: 'fp', matchPattern: 'FindPeople' },
];
