import { type Module } from 'mini-common-schema';
import { type ServerAppName } from 'mini-app-constants';
import type { SupportedModules } from 'mini-common-schema';

const MODULE_APP_MAPPING: Record<SupportedModules, ServerAppName> = {
    [0]: 'Mini',
    [1]: 'MiniCalendar',
    [2]: 'MiniPeople',
    [3]: 'MiniRewards',
};

export const getServerAppNameForModule = (module: Module): ServerAppName =>
    MODULE_APP_MAPPING[module] ?? 'Mini';
