import createBootError from 'owa-shared-start/lib/createBootError';
import getScopedPath from 'owa-url/lib/getScopedPath';
import { createStatusErrorMessage } from 'owa-shared-start/lib/createStatusErrorMessage';
import { getHostAppEcsFilter, getHostedUxEcsFilter } from 'owa-config/lib/getHostAppEcsFilters';
import { getOwsPath } from 'owa-config';
import { type HttpStatusCode } from 'owa-http-status-codes/lib/HttpStatusCode';
import { makeServiceRequest } from 'owa-service/lib/ServiceRequest';
import { onBeforeRetry } from 'owa-shared-start/lib/onBeforeRetry';
import { updateDiagnosticsOnResponse } from 'owa-config/lib/envDiagnostics';
import type { HeadersWithoutIterator } from 'owa-service/lib/RequestOptions';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import type { BootError } from 'owa-shared-start-types';

export function overrideIsRetriableStatus(status: number): boolean {
    return status === 204 || status === 404 || status >= 500;
}

export function fetchData(
    headers: HeadersWithoutIterator,
    postProcessFunction: (json: SessionData) => SessionData,
    processHeaders: (headers: HeadersWithoutIterator) => void
): Promise<SessionData> {
    const endpoint = getScopedPath(getOwsPath()) + '/startupdata.ashx';

    headers.append('x-owa-host-app', getHostAppEcsFilter());
    headers.append('x-owa-hosted-ux', getHostedUxEcsFilter());

    return makeServiceRequest<Response>('StartupData', undefined, {
        endpoint: endpoint,
        headers,
        returnFullResponseOnSuccess: true,
        shouldRetry: status => Promise.resolve(overrideIsRetriableStatus(status)),
        onBeforeRetry: response => onBeforeRetry(endpoint, response),
        authNeededOnUnAuthorized: false,
        retryCount: 5,
    })
        .then(
            (response: Response) => {
                updateDiagnosticsOnResponse(response);
                if (!response) {
                    throw new Error('NoResponse');
                }
                // it is important that we check for not a status of 200 instead of !response.ok since
                // OwaInvalidUserLanguageException returns with a status of 204
                if (response.status !== 200) {
                    throw createStatusErrorMessage(response);
                }

                processHeaders(response.headers);

                return response.json().catch(e => {
                    const invalidJsonError: BootError = new Error('InvalidJson');
                    if (e) {
                        e.diagnosticInfo = e.message;
                    }
                    throw invalidJsonError;
                });
            },
            response => {
                if (response instanceof Error) {
                    throw response;
                } else {
                    throw createStatusErrorMessage(response);
                }
            }
        )
        .then(postProcessFunction)
        .catch(e => Promise.reject(createBootError(e, 'StartupData', endpoint, 0)));
}
