import { createPerformanceObserver } from './createPerformanceObserver';

export interface ResourceToTrack<T = string> {
    name: T;
    matchPattern: string;
}

// singleton observer interface
let performanceObserver = null;

// performance timing entries cached for future access
const performanceResourceTimingEntriesCache = new Map<string, PerformanceResourceTiming[]>();

// list of resource we want to track
const resourcesToTrack = new Set<ResourceToTrack>();

/**
 * Compares the performance entry name with tracked resources and returns the
 * ResourceToTrack object if there is a match
 * @param name
 * @returns
 */
const getResourceDetailsIfTracked = (performanceEntryName: string): ResourceToTrack => {
    for (const pattern of resourcesToTrack.entries()) {
        if (performanceEntryName.indexOf(pattern[1].matchPattern) > -1) {
            return pattern[1];
        }
    }
    return null;
};

const PERFORMANCE_ENTRY_LISTENERS_MAP = new Map<
    string,
    ((performanceEntry: PerformanceResourceTiming) => void)[]
>();

/**
 * Notify all the listener awaiting on the PerformanceResourceTiming data for a resourceName
 * @param resourceName
 * @param performanceEntry
 */
const notifyListeners = (resourceName: string, performanceEntry: PerformanceResourceTiming) => {
    PERFORMANCE_ENTRY_LISTENERS_MAP.get(resourceName)?.forEach(resolve =>
        resolve(performanceEntry)
    );
    PERFORMANCE_ENTRY_LISTENERS_MAP.delete(resourceName);
};

const processPerformanceEntries = (list: PerformanceObserverEntryList) => {
    const entries = list.getEntries();

    entries.forEach(entry => {
        const { name } = entry;
        const trackedResource = getResourceDetailsIfTracked(name);

        if (trackedResource) {
            const { name: trackedResourceName } = trackedResource;
            if (!performanceResourceTimingEntriesCache.has(trackedResourceName)) {
                performanceResourceTimingEntriesCache.set(trackedResourceName, []);
            }

            performanceResourceTimingEntriesCache
                .get(trackedResourceName)
                .push(entry as PerformanceResourceTiming);

            notifyListeners(trackedResourceName, entry as PerformanceResourceTiming);
        }
    });
};

const registerPerformanceObserver = () => {
    performanceObserver = createPerformanceObserver('resource', processPerformanceEntries);

    // start observing
    performanceObserver?.observe({ type: 'resource', buffered: true });
};

const pickPerformanceResourceEntry = (performanceEntries: PerformanceResourceTiming[]) => {
    if (!performanceEntries) {
        return null;
    }

    // get the fetch triggered by script over SW cached entry
    const scriptInitiatedEntry = performanceEntries.find(e => e.initiatorType === 'script');
    const perfEntry = scriptInitiatedEntry
        ? scriptInitiatedEntry
        : performanceEntries[performanceEntries.length - 1];
    return perfEntry;
};

/**
 * This function create a singleton performance observer and adds the resource to the tracking list.
 * @param resourceToTrack
 */
export const observeResourcePerformance = (resourceToTrack: ResourceToTrack) => {
    if (!performanceObserver) {
        registerPerformanceObserver();
    }

    // add to the list of resources being tracked
    resourcesToTrack.add(resourceToTrack);
};

/**
 * Returns a promise that resolves to the performance entry when the resource is loaded.
 * @param resourceName
 * @returns PerformanceResourceTiming
 */
export const getPerformanceEntryForResourceAsync = (
    resourceName: string
): Promise<PerformanceResourceTiming> => {
    if (performanceResourceTimingEntriesCache.has(resourceName)) {
        return Promise.resolve(
            pickPerformanceResourceEntry(performanceResourceTimingEntriesCache.get(resourceName))
        );
    } else {
        // tslint:disable-next-line promise-must-complete
        return new Promise(resolve => {
            if (PERFORMANCE_ENTRY_LISTENERS_MAP.has(resourceName)) {
                PERFORMANCE_ENTRY_LISTENERS_MAP.get(resourceName).push(resolve);
            } else {
                PERFORMANCE_ENTRY_LISTENERS_MAP.set(resourceName, [resolve]);
            }
        });
    }
};

/**
 * This function returns the last recorded performance entry for a resource.
 * @param resourceName
 * @returns PerformanceResourceTiming | null
 */
export const getPerformanceEntryForResourceSync = (
    resourceName: string
): PerformanceResourceTiming => {
    if (performanceResourceTimingEntriesCache.has(resourceName)) {
        return pickPerformanceResourceEntry(
            performanceResourceTimingEntriesCache.get(resourceName)
        );
    }

    return null;
};
