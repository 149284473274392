import createBootError from 'owa-shared-start/lib/createBootError';
import getScopedPath from 'owa-url/lib/getScopedPath';
import getSessionData from './utils/getSessionData';
import { getApp, getOwsPath } from 'owa-config';
import { isBootFeatureEnabled } from 'owa-metatags';
import { isMsalEnabled } from 'mini-common-utils/lib/utils/isMsalEnabled';
import { markFunction, trackBottleneck } from 'owa-performance';
import { onBootComplete } from 'owa-shared-start/lib/onBootComplete';
import { onBootError } from 'owa-shared-start/lib/onBootError';
import { setApp } from 'owa-config/lib/bootstrapOptions';
import { setAriaTenantToken } from 'owa-shared-start/lib/ariaUtils';
import { setAuthTiming } from 'owa-auth-timings';
import { setBootFailureCount } from 'owa-shared-start/lib/bootErrorCounter';
import { setIsDeepLink } from 'owa-url/lib/isDeepLink';
import { setThreadName } from 'owa-thread-config';
import { unblockLazyLoadCallbacks } from 'owa-bundling-light';
import { updateServiceConfig } from 'owa-service/lib/config';
import {
    lazyGetAnchorMailboxProxy,
    lazyGetAuthTokenMsalProxy,
    lazyCreateMsalInstanceProxy,
    lazyOnActivityTimeoutErrorForMSALProxy,
} from 'owa-msaljs/lib/lazyAppBoot';
import type StartConfig from './types/StartConfig';
import type { PromiseWithKey } from 'owa-performance';
import type { HeadersWithoutIterator } from 'owa-service/lib/RequestOptions';
import type { MailboxInfo } from 'owa-client-types';

let startTime: number;

/* eslint-disable-next-line @typescript-eslint/no-explicit-any --
 * See aka.ms/mini-lint-errors
 *	> Unexpected any. Specify a different type. */
export async function miniStart(config: StartConfig): Promise<any> {
    try {
        await internalStart(config);

        try {
            // Unblocking lazy-loading post successful boot
            unblockLazyLoadCallbacks();
            onBootComplete(config, startTime);

            // call loader removed callbacks
            config.onLoaderRemoved?.();

            setBootFailureCount(0);
        } catch (error) {
            throw createBootError(error, 'BootComplete');
        }
    } catch (bootError) {
        // Shared boot error logic with big OWA which uses default handleBootError function in owa-shared-start
        onBootError(bootError, config);
    }
}

async function internalStart(config: StartConfig) {
    try {
        startTime = Date.now();
        // @ts-ignore - This flight does not exist in big OWA
        if (isBootFeatureEnabled('mini-analytics-worker')) {
            // Setting main thread name for supporting analytics worker
            setThreadName('MAIN_THREAD');
        }

        // don't reset the app if it is already set
        if (!getApp()) {
            // @ts-ignore - The App string is different for Mini
            setApp(config.app);
        }

        setIsDeepLink(config.isDeepLink);

        setAriaTenantToken(config.startupAriaToken);

        const runBeforeStartPromise: Promise<void> | undefined = config
            .runBeforeStart?.(config)
            .catch(error => {
                if (!error.source) {
                    error.source = 'BeforeBoot';
                }
                throw error;
            });

        updateServiceConfig({
            baseUrl: getScopedPath(getOwsPath()),
            appName: config.serverAppName,
        });

        /* Initialize MSAL flow for consumer
         * Add AnchorMailbox header to all the requests using MsAuth token. */
        if (isMsalEnabled()) {
            // Create the MSAL instance with the correct app-specific parameters here in order to
            // be able to make the startupdata request before we start evaluating other boot bundles
            setAuthTiming('msalis');

            await lazyCreateMsalInstanceProxy.importAndExecute(config.msalConfiguration);

            setAuthTiming('msalie');

            const getAuthToken = (headers?: HeadersWithoutIterator, mailboxInfo?: MailboxInfo) =>
                lazyGetAuthTokenMsalProxy.importAndExecute(headers, mailboxInfo);

            updateServiceConfig({
                getAuthToken: (headers?: HeadersWithoutIterator, mailboxInfo?: MailboxInfo) => {
                    setAuthTiming('fgatmsals'); // first getAuthToken call in session
                    const results = getAuthToken(headers, mailboxInfo);
                    results
                        .then(() => {
                            setAuthTiming('fgatmsalsc');
                        })
                        .catch(e => {
                            setAuthTiming('fgatmsale');
                            throw e;
                        });
                    updateServiceConfig({ getAuthToken });
                    return results;
                },
                getAnchorMailbox: (mailboxInfo?: MailboxInfo) =>
                    lazyGetAnchorMailboxProxy.importAndExecute(mailboxInfo),
                onAuthFailed: () => {},
                onActivityTimeoutError: () =>
                    lazyOnActivityTimeoutErrorForMSALProxy.importAndExecute(),
            });
        }

        const sessionDataFunction = config.overrideBootPromises || getSessionData;
        const sessionDataPromise = runBeforeStartPromise
            ? runBeforeStartPromise.then(sessionDataFunction)
            : sessionDataFunction();

        const javascriptPromise = markFunction(() => config.bootstrap.import(), 'mjs')();

        /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
         * See aka.ms/mini-lint-errors
         *	> Unexpected any. Specify a different type. */
        const bootPromises: PromiseWithKey<any>[] = [
            { promise: sessionDataPromise, key: 'sd' },
            { promise: javascriptPromise, key: 'js' },
        ];

        // TODO: Do we need getOpxHostData? Since it's already included in bootstrap flow

        /* eslint-disable-next-line @typescript-eslint/no-explicit-any --
         * See aka.ms/mini-lint-errors
         *	> Unexpected any. Specify a different type. */
        const bootstrapPromises: Promise<any>[] = [javascriptPromise];

        bootPromises.push({
            promise: Promise.all(bootstrapPromises)
                .then(([bootFunc]) => {
                    try {
                        return bootFunc.apply(null, [sessionDataPromise]);
                    } catch (e) {
                        throw createBootError(e, 'Bootstrap');
                    }
                })
                .catch(e => {
                    throw createBootError(e, 'Script');
                }),
            key: null,
        });

        if (config.runAfterRequests) {
            config.runAfterRequests(sessionDataPromise);
        }

        return trackBottleneck('start', bootPromises);
    } catch (error) {
        return Promise.reject(createBootError(error, 'Preboot'));
    }
}
